import { Link } from 'react-router-dom';
import { Desktop } from '../../../helpers/devices';
import HeaderProfile from '../../header-profile';
import SidebarBonus from '../sidebar-bonus';
import HeaderLogin from '../../header-login';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { profileSelectors } from '../../../redux/profile/selectors';
import { gameReducers } from '../../../redux/games/games_list/slice';
import React from 'react';
import { textForLinkLightHouse } from '../../../config';
import LazyImage from '../../LazyImage/LazyImage';

const {
	selectAuthToken,
} = profileSelectors;

const {
	selectFirstCategory,
	setSelectedProviders,
} = gameReducers;
const SidebarMiddle: React.FC = () => {
	const authToken = useAppSelector(selectAuthToken);
	const dispatch = useAppDispatch();
	const location = window.location.origin;

	const onHomePage = () => {
		dispatch(selectFirstCategory());
		dispatch(setSelectedProviders([]));
	};

	return (
		<>
			<Desktop>
				<div onClick={onHomePage} className="main_logo">
					<Link className='link_text_hide' to={location}>
						{textForLinkLightHouse}
						<LazyImage width="258" height="50" src="/resources/images/logo_main.png" alt="" title=""  />
					</Link>
				</div>
			</Desktop>
			{
				authToken ?
					<Desktop>
						<div className="sign_block">
							<HeaderProfile/>
						</div>
					</Desktop> :
					<div className="sign_block">
						{/*<Desktop>*/}
						{/*	<Link className="tournament_btn icon_cup"*/}
						{/*		style={ pathname.includes(`${ROUTE_PATH.root}${ROUTE_PATH.tournament}`) ? { backgroundColor: '#ff472e',color: '#ffffff' } : null }*/}
						{/*		to={ROUTE_PATH.tournament}>*/}
						{/*		<span className='btn_text'><FormattedMessage id="tournaments"/></span>*/}
						{/*	</Link>*/}
						{/*</Desktop>*/}
						<SidebarBonus/>
						<HeaderLogin />
					</div>
			}
		</>
	);
};

export default SidebarMiddle;
