import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { storeDenyDate } from '../../helpers/localStorageUtils';
import './pwa.scss';
import { translate } from '../../translations/utils';
import LazyImage from '../LazyImage/LazyImage';

const timeout = 1000; // Time to delay the modal for lighthouse performance

//pwa_accept click handler added in pwa.js (src/pwa.js)
//styles in App.scss
const PwaComponent: React.FC = () => {
	const [visibleInDOM, setVisibleInDOM] = useState(false);

	const handleDenyClick = () => {
		storeDenyDate(new Date());
		const pwaBlock = document.getElementById('pwa_block');
		if (pwaBlock) {
			pwaBlock.style.opacity = '1';
			setTimeout(() => {
				pwaBlock.style.display = 'none';
				setVisibleInDOM(false);
			}, 400);
		}
	};

	useEffect(() => {
		const id = setTimeout(() => {
			setVisibleInDOM(true);
		}, timeout);

		return () => clearTimeout(id);
	}, []);

	if (!visibleInDOM) return null;

	return (
		<div id="pwa_block">
			<div className="block_inner">
				<div className="image_block">
					<LazyImage width="16" height="16" src="/resources/images/logo_footer.png" alt="" title="" />
				</div>
				<div className="info_block">
					<div className="info_inner">
						<div className="pwa_title">
							<FormattedMessage id="pwa.title"/>
						</div>
						<div className="pwa_text">
							<FormattedMessage id="pwa.text"/>
						</div>
					</div>
					<div className="pwa_buttons">
						<button aria-label={translate('pwa.accept')} className="accept_btn" id="pwa_accept">
							<FormattedMessage id="pwa.accept"/>
						</button>
						<button aria-label={translate('close_btn')} className="deny_btn icon_close"
							onClick={handleDenyClick}></button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PwaComponent;
