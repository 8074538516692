import { NumberOrNull, StringOrNull } from '../../types/types';
import { dateType } from '../../components/history/filters/types';
import { ICasinoBonusesItem, ICasinoSpinItem } from '../bonus/types';
import { TRANSACTION_HISTORY_STATUS, TRANSACTION_TYPES } from '../../components/history/constants';

export interface IHistoryInitState {
	casinoHistory           : ICasinoHistory;
	casinoSpins             : ICasinoSpins;
	casinoBonuses           : ICasinoBonuses;
	depositHistory          : IDepositHistory;
	withdrawalsHistory      : IWithdrawalHistory;
	balanceAdjustmentHistory: IBalanceAdjustmentHistory;
	transactionsHistory     : ITransactionsHistory;
}

export interface IHistoryFilters<TStatus = NumberOrNull> {
	page            : number;
	limit           : number;
	place_date_from?: dateType | string;
	place_date_to?  : dateType | string;
	status_id?      : TStatus;
}

export interface HistoryBase<T = void> {
	data     : T[];
	dataCount: number;
	filters  : IHistoryFilters;
	UI: {
		loading: boolean;
	};
}
export interface ICasinoHistoryItem {
	id           : number;
	user_id      : number;
	money_type_id: MONEY_TYPE | null;
	amount       : number;
	won_amount   : number;
	game_id      : number | null;
	game_name    : string;
	status_id    : number;
	created      : string;
	provider_name: string;
}

export interface IBalanceAdjustmentItem {
	id         : number;
	payment_id : number;
	date       : string;
	amount     : number;
	status_id  : number;
	status_name: string;
	type_id    : number;
	bonus_type : StringOrNull;
	reason     : StringOrNull;

}
export interface ICasinoDepositItem {
	id          : number;
	payment_id  : number;
	payment_name: string;
	date        : string;
	amount      : number;
	status_id   : number;
	status_name : string;
	type_id     : number;
	bonus_type  : string | null;
	reason      : string | null;
}

export interface ICasinoTransactionItem {
	id            : number;
	payment_id    : number;
	payment_name  : string;
	date          : string;
	amount        : number;
	status_id     : number;
	status_name   : WITHDRAW_STATUS_NAMES;
	type_id       : TRANSACTION_TYPES;
	bonus_type    : string | null;
	reason        : string | null;
	balance_after : number;
	balance_before: number;
}

export interface ICasinoFilters extends IHistoryFilters {}
export interface ICasinoHistory extends HistoryBase<ICasinoHistoryItem>{
	filters: ICasinoFilters;
}

export interface ICasinoSpins extends Omit<HistoryBase<ICasinoSpinItem>, 'data' | 'dataCount' | 'UI'> {}

export interface IHistoryWithdrawalItem {
	id          : number;
	date        : string;
	amount      : number;
	payment_name: string;
	status_name : WITHDRAW_STATUS_NAMES;
	reason      : StringOrNull;
	status_id   : TRANSACTION_HISTORY_STATUS;
}

export interface ICasinoBonuses extends Omit<HistoryBase<ICasinoBonusesItem>, 'data' | 'dataCount' | 'UI'> {}

export interface IDepositHistory extends HistoryBase<ICasinoDepositItem>{}

export interface IWithdrawalHistory extends HistoryBase<IHistoryWithdrawalItem>{}

export interface ITransactionsHistory extends HistoryBase<ICasinoTransactionItem>{}

export interface IBalanceAdjustmentHistory extends HistoryBase<IBalanceAdjustmentItem>{
	balanceAdjustmentExist: boolean;
}

export interface ISetFiltersPayload {
	type   : HISTORY_FILTERS;
	filters: IHistoryFilters;
}

export interface IFilterRefreshPayload {
	type  : HISTORY_FILTERS;
	filter: IHistoryFilters;
}

export interface IHistoryStore {
	casinoFilters           : ICasinoFilters;
	bonusesFilters          : IHistoryFilters;
	spinsFilters            : IHistoryFilters;
	depositFilters          : IHistoryFilters;
	withdrawalsFilters      : IHistoryFilters;
	balanceAdjustmentFilters: IHistoryFilters;
	transactionsFilters     : IHistoryFilters;
	isFirstLoad             : boolean;
}

export enum HISTORY_FILTERS {
	CASINO_HISTORY = 'casinoHistory',
	CASINO_SPINS = 'casinoSpins',
	CASINO_BONUSES = 'casinoBonuses',
	DEPOSIT_HISTORY = 'depositHistory',
	WITHDRAWALS_HISTORY = 'withdrawalsHistory',
	BALANCE_ADJUSTMENT_HISTORY = 'balanceAdjustmentHistory',
	TRANSACTIONS_HISTORY = 'transactionsHistory',
}

export enum CASINO_HISTORY_STATUSES {
	ACTIVE = 1,
	WON = 2,
	LOST = 3,
	REVERSED = 4,
}

export enum MONEY_TYPE {
	REAL = 1,
	VIRTUAL
}

export enum WITHDRAW_STATUS_NAMES {
	IN_REVIEW = 'IN_REVIEW',
	CANCELLED_BY_ADMIN = 'CANCELLED_BY_ADMIN',
	CANCELLED = 'CANCELLED',
	PENDING = 'PENDING',
	SUCCESS = 'SUCCESS',
	REFUND = 'REFUND',
	REFUNDING = 'REFUNDING',
	EXPIRED = 'EXPIRED',
	PROCCESSING =  'PROCCESSING',
	PARTIAL_REFUND = 'PARTIAL_REFUND',
	FAILED = 'FAILED',
	APPROVED = 'APPROVED',

}

export interface TransactionSources {
	[key: number]: string;
}